import PropTypes from "prop-types";
import React from "react";
import { Icon, Row, Col, Typography } from "antd";
const { Title } = Typography;
import { graphql } from "gatsby";
import { ThemeContext } from "../layouts";
import Article from "../components/Article";
import Contact from "../components/Contact";
import Headline from "../components/Article/Headline";
import Seo from "../components/Seo";
import MapaCard from "../components/Mapa/MapaCard";

const ContactPage = props => {
  const {
    data: {
      site: {
        siteMetadata: { facebook }
      }
    }
  } = props;

  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Article theme={theme}>
            <header>
              <Headline title="Contacto" theme={theme} />

              <Title level={4} style={{ marginBottom: "1em", textAlign: "left" }}>
                <div style={{ marginBottom: "0.5em" }}>
                  <Icon type="phone" style={styles.icon} />
                  +56 9 9319 9305<br />
                </div>
                <Icon type="mail" style={{ ...styles.icon, fontSize: 26 }} />
                info@welinux.cl
              </Title>
              <p style={{ marginBottom: "28px" }}>
                Ya sea que desees cotizar un proyecto, necesites asesoría, una demostración o
                cualquier otra cosa, estamos listos para responder todas tus preguntas.
              </p>

              <Row gutter={24}>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Contact theme={theme} />
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <MapaCard />
                </Col>
              </Row>
            </header>
          </Article>
        )}
      </ThemeContext.Consumer>

      <Seo facebook={facebook} />
    </React.Fragment>
  );
};

const styles = {
  icon: { fontSize: 30, verticalAlign: "middle", marginRight: "0.35em" }
};
ContactPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default ContactPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`;
